import React from "react";
import Chat from "./Components/Chat";
import { useSupport } from "./Hooks";

const Support = () => {
  const { list, loading, form, setForm, getChat, chat, submitAnswer } =
    useSupport();

  return (
    <div>
      <div className="card card-chat overflow-hidden">
        <div className="card-body d-flex p-0 h-100">
          <Chat
            form={form}
            loading={loading}
            chat={chat}
            setForm={setForm}
            submitAnswer={submitAnswer}
          />
        </div>
      </div>
    </div>
  );
};

export default Support;
